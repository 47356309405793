import { useEffect, useState } from 'react';
import moment from 'moment';
import { Title } from 'react-admin';
import { Alert, CircularProgress, IconButton, List, ListItem, ListItemText, Pagination, Snackbar } from '@mui/material';
import { Download } from '@mui/icons-material';
import { ERROR, HISTORICAL } from '../constants';
import { getHistoricalData, downloadFile } from '../api/api';
import './historical.css';

const DownloadButton = ({ fileInfo, onDownLoadClick }) =>
    <IconButton edge={'end'} aria-label={'delete'} onClick={() => onDownLoadClick(fileInfo)}>
        <Download/>
    </IconButton>;

const HistoricalItem = ({ fileInfo, onDownLoadClick }) => {
    const secondaryText = `${moment(fileInfo.uploadTime).format('DD/MM/YY HH:mm')} - ${fileInfo.uploadedBy}`;
    return <ListItem
        divider
        secondaryAction={<DownloadButton fileInfo={fileInfo} onDownLoadClick={onDownLoadClick} />}
    >
        <ListItemText
            primary={<span>{fileInfo.clientFileName}</span>}
            secondary={<span className='dateUserUploader' title={secondaryText}>{secondaryText}</span>}
        />
    </ListItem>
};

const Historical = () => {
    const [historicalData, setHistoricalData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getHistoricalData()
            .then(response => {
                setHistoricalData(response.data);
                setTotalPages(Math.ceil(response.data.length / 10));
            })
            .catch(e => setHistoricalData({ error: e.message }))
            .finally(() => setIsLoading(false))
    }, []);

    const onDownLoadClick = fileInfo => {
        downloadFile(fileInfo.fileName)
        .then(({ data, headers}) => {
            const blob = new Blob([data], { type: headers['content-type'] });
            const alink = document.createElement('a');
            alink.href = URL.createObjectURL(blob);
            alink.download = fileInfo.clientFileName;
            alink.click();
            alink.remove();
        })
        .catch(() => setShowAlert(true))
    }

    const generateHistoricalList = () => {
        if (historicalData.error) {
            return <p className={'historicalError'}>{ERROR.HISTORICAL}</p>
        }
        if (historicalData.length) {
            const startIndex = (currentPage - 1) * 10;
            return <List dense disablePadding>
                {historicalData.slice(startIndex, startIndex + 10).map((fileInfo, index) =>
                    <HistoricalItem key={index} fileInfo={fileInfo} onDownLoadClick={onDownLoadClick} />)}
            </List>;
        }
    };

    return <div className={'historicalContainer'}>
        <Title title={HISTORICAL.MENU} />
        <div className={'historicalForm'}>
            {isLoading && <CircularProgress />}
            {historicalData && generateHistoricalList()}
        </div>
        {totalPages && <Pagination
            id={'historicalPagination'}
            count={totalPages}
            boundaryCount={0}
            onChange={(e, selectedPage) => setCurrentPage(selectedPage)}
            page={currentPage}
            siblingCount={1}
        />}
        <div style={{ position: 'relative', width: '300px' }}>
            <Snackbar id={'downloadErrorAlert'} open={showAlert} autoHideDuration={4000} onClose={() => setShowAlert(false)} >
                <Alert onClose={() => setShowAlert(false)} severity={'error'}>
                    {ERROR.DOWNLOAD}
                </Alert>
            </Snackbar>
        </div>
    </div>
}

export default Historical;
import { useState } from 'react';
import { Layout, Menu } from 'react-admin';
import { History, Search, UploadFile } from '@mui/icons-material';
import { DesktopAppBar, MobileAppBar } from './AppBar';
import { HISTORICAL, SEARCH, UPLOAD_FILE } from '../constants';
import './layout.css';

const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

const BlacklistMenu = () => {
    const [selectedItem, setSelectedItem] = useState();

    return <Menu>
        <Menu.Item
            to={SEARCH.URL}
            primaryText={SEARCH.MENU} leftIcon={<Search />}
            selected={selectedItem === 0} 
            onClick={() => setSelectedItem(0)}
        />
        <Menu.Item
            to={UPLOAD_FILE.URL}
            primaryText={UPLOAD_FILE.MENU} leftIcon={<UploadFile />}
            selected={selectedItem === 1}  
            onClick={() => setSelectedItem(1)} 
        />
        <Menu.Item
            to={HISTORICAL.URL}
            primaryText={HISTORICAL.MENU} leftIcon={<History />}
            selected={selectedItem === 2}  
            onClick={() => setSelectedItem(2)} 
        />
    </Menu>;
}

const BlacklistLayout = props => <Layout
    {...props}
    className={isMobile() && 'mobileLayout'}
    menu={BlacklistMenu}
    appBar={isMobile() ? MobileAppBar : DesktopAppBar}
/>;

export default BlacklistLayout;
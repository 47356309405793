import { utils, read, SSF } from 'xlsx';
import jwt from 'jwt-decode'
import moment from 'moment';
import { ERROR } from '../constants';

export const parseFile = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = e => {
            try {
                const workbook = read(e.target.result, { type : 'binary' });
                validateHeaders(workbook.Strings);
                const firstSheetData = workbook.Sheets[workbook.SheetNames[0]];
                const firstSheetRows = utils.sheet_to_row_object_array(firstSheetData);
                const parsedData = {
                    UploadedBy: getUserName(),
                    Blacklist: firstSheetRows.map(validateRow)
                };
                resolve(parsedData);
            } catch(e) {
                reject(e || new Error(ERROR.VALIDATION));
            }
        } 
    });
    
}

const validateHeaders = strings => {
    if (!strings.length) throw new Error(ERROR.EMPTY);
    if (!strings[0].t.toLowerCase().includes('identificación') ||
    strings[1].t.toLowerCase() !== 'fecha inicio inhabilitación' ||
    strings[2].t.toLowerCase() !== 'fecha fin inhabilitación')
        throw new Error(ERROR.HEADERS);
}
    

const validateRow = row => {
    const values = Object.values(row);
    const start_date = formatDateValue(values[1]);
    const end_date = formatDateValue(values[2]);
    if (values[0].length > 12)
        throw new Error(ERROR.INVALID_DNI);
    if (moment(end_date).isBefore(moment(start_date)) || end_date === start_date)
        throw new Error(ERROR.INVALID_START_END);

    return { dni: values[0], start_date, end_date }
}

const formatDateValue = dateValue => {
    const parsedDateObj = SSF.parse_date_code(dateValue);
    const parsedDateString = moment(parsedDateObj).format('YYYY-mm-DD');
    if (parsedDateString === 'Invalid date' || parsedDateString.length !== 10)
        throw new Error(ERROR.INVALID_DATE);
    return parsedDateString;
}

const getUserName = () => {
    const BLToken = sessionStorage.getItem('BLJWT');
    if (BLToken) {
        const decodedToken = jwt(BLToken);
        const mail = decodedToken.emails[0];
        return mail.split('@')[0];
    }
};

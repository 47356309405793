import jwt from 'jwt-decode';
import { AppBar } from 'react-admin';
import Typography from '@mui/material/Typography';
import LaLigaTechLogo from '../laLigaTechLogoSmall.png';

const getMailFromToken = () => {
    const BLToken = sessionStorage.getItem('BLJWT');
    if (BLToken) {
        const decodedToken = jwt(BLToken);
        return decodedToken.emails[0];
    }
}

export const DesktopAppBar = props => <AppBar style={{backgroundColor: "#B0DA00"}} {...props}>
    <Typography variant={'h6'} id={'react-admin-title'} />
    <img id={'LLT-logo'} src={LaLigaTechLogo} alt={'La Liga Tech'}/>
    <div>{getMailFromToken()}</div>
</AppBar>;

export const MobileAppBar = props => <div className={'mobileAppBarContainer'}>
    <div className={'logoContainer'}>
        <img id={'LLT-logo'} src={LaLigaTechLogo} alt={'La Liga Tech'}/>
        <div className={'userName'}>{getMailFromToken()}</div>
    </div>
    <AppBar style={{backgroundColor: "#B0DA00"}} {...props}>
        <Typography variant={'h6'} id={'react-admin-title'} />
    </AppBar>
</div>;
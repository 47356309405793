export const SEARCH = {
    MENU: 'Buscar',
    URL: 'search'
};

export const UPLOAD_FILE = {
    MENU: 'Subir archivo',
    URL: 'upload'
};

export const HISTORICAL = {
    MENU: 'Historial',
    URL: 'historical'
};

export const ENTER_ID = 'Debe ingresar un Nro de Documento';
export const INVALID_DATE_FORMAT = 'Debe ingresar una fecha valida DD/MM/YYYY'
export const SELECT_FILE = 'Seleccione un archivo';
export const EXCEL_FORMAT = 'Solo archivos .xlsx o .xls';

export const ERROR = {
    BANNED: 'El documento ingresado se encuentra en la blacklist',
    DOWNLOAD: 'Error al descargar, intente nuevamente',
    EMPTY: 'El documento esta vacío.',
    EXCEL_FORMAT: 'Archivo inválido. Sólo se permiten archivos excel (.xlsx o .xls)',
    HEADERS: 'Los encabezados deben ser "Nº Identificación", "Fecha inicio inhabilitación" y "Fecha fin inhabilitación". Por favor verifique.',
    HISTORICAL: 'Ocurrió un error al cargar la lista, intente nuevamente.',
    INVALID_DATE: 'El documento contiene una fecha inválida. Asegúrese de que el formato sea DD/MM/AAAA',
    INVALID_DNI: 'El documento contiene un DNI inválido. Como máximo se esperan 12 caracteres.',
    INVALID_START_END: 'Una de las fechas de fin no es posterior a la de inicio. Por favor verifique.',
    SEARCH: 'Ocurrio un error en la consulta, intente nuevamente.',
    UPLOAD: 'Ocurrió un error en la subida, intente nuevamente',
    VALIDATION: 'Ocurrió un error al validar el archivo.'
};

export const SUCCESS = {
    NOT_BANNED: 'El documento ingresado no se encuentra en la blacklist',
    UPLOAD: 'El archivo se subió correctamente'
};
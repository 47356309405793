import jwt from 'jwt-decode';
import { useEffect, useState } from 'react';
import { Admin, CustomRoutes, Title } from 'react-admin';
import { Route } from 'react-router-dom';
import BlacklistLayout from './layout/Layout';
import SearchPage from './search/SearchPage';
import UploadFile from './uploadFile/UploadFile';
import Historical from './historical/Historical';
import LogInPage from './logIn/LogInPage';
import { HISTORICAL, SEARCH, UPLOAD_FILE } from './constants';
import './App.css';

const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isAllow, setIsAllow] = useState(false);

    useEffect(() => {
        if (!isLoggedIn && window.location.hash.includes('id_token')) {
            const codeParam = window.location.hash.split('=')[1];
            sessionStorage.setItem('BLJWT', codeParam || '');
        }
        
        const BLToken = sessionStorage.getItem('BLJWT');
        if (BLToken) {
            setIsLoggedIn(true);
            verifyRole(BLToken);
        }
    }, [isLoggedIn]);

    const verifyRole = token => {
        const decodedToken = jwt(token);
        const role = decodedToken['jobTitle'];
        
        if (role === 'blacklistuser') {
            window.location.hash = '#/search';
            setIsAllow(true);
            return;
        }

        window.location.hash = '';
        sessionStorage.removeItem('BLJWT');
    };

    if (!isLoggedIn) return <LogInPage />
    if (!isAllow) return <LogInPage notAllowed />
    
    return <Admin layout={BlacklistLayout}>
        <Title title={'Blacklist'} />
        <CustomRoutes>
            <Route path={SEARCH.URL} element={<SearchPage />} />
            <Route path={UPLOAD_FILE.URL} element={<UploadFile />} />
            <Route path={HISTORICAL.URL} element={<Historical />} />
        </CustomRoutes>
    </Admin>;
};

export default App;

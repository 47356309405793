import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Title } from 'react-admin';
import { Button, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { ENTER_ID, ERROR, INVALID_DATE_FORMAT, SEARCH, SUCCESS } from '../constants';
import { checkFanStatus } from '../api/api';
import './searchPage.css';

const SearchPage = () => {
    const [date, setDate] = useState(moment());
    const [dni, setDni] = useState('');
    const [messageResult, setMessageResult] = useState('');

    useEffect(() => { updateMessage(); }, [dni, date]);

    const updateMessage = (message, status) => {
        setMessageResult(message || '');
        document.getElementById('searchMessageResult').className = status || '';
    };

    const searchIndividual = () => {
        if (!dni.length) {
            setMessageResult(ENTER_ID);
            return;
        }
        if (!moment(date, 'DD/MM/YYYY', true).isValid()) {
            setMessageResult(INVALID_DATE_FORMAT);
            return;
        }

        checkFanStatus(dni.trim().toUpperCase(), date.format('yyyy-MM-DD'))
            .then(response => {
                if (Boolean(response.data.isOnBlackList))
                    updateMessage(ERROR.BANNED, 'error');
                else
                    updateMessage(SUCCESS.NOT_BANNED, 'success');
            })
            .catch(() => updateMessage(ERROR.SEARCH, 'error'));
    }

    return <div className={'searchPageContainer'}>
        <Title title={SEARCH.MENU} />
        <div className='searchPageForm'>
            <TextField label={'Nro de Documento'} onChange={e => setDni(e.target.value)} inputProps={{ maxLength: 12 }} />
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                    label={'Fecha'}
                    inputFormat={'DD/MM/YYYY'}
                    value={date}
                    onChange={newValue => setDate(newValue)}
                    renderInput={params => <TextField {...params} />}
                />
            </LocalizationProvider>
        </div>
        <Button style={{backgroundColor: "#B0DA00"}} variant={'contained'} onClick={searchIndividual}>{SEARCH.MENU.toUpperCase()}</Button>
        <p id={'searchMessageResult'}>{messageResult}</p>
    </div>;
};

export default SearchPage;
import axios from 'axios';

const host = window.api ?? ''

const prefix = '/bl-api/api'

const apiPrefix = host+prefix

const getToken = () => ({ Authorization: `Bearer ${sessionStorage.getItem('BLJWT')}` });

export const checkFanStatus = (id, date) => axios.get(`${apiPrefix}/checkFanStatus?date=${date}&documentId=${id}`, { headers: getToken() });

export const uploadExcelData = (data, FileName) =>
    axios.post(`${apiPrefix}/uploadFile`,
        { ...data, FileName },
        { headers: getToken() }
    );

export const getHistoricalData = () => axios.get(`${apiPrefix}/historicalFiles`, { headers: getToken() });

export const downloadFile = fileName => axios.get(
    `${apiPrefix}/downloadFile?FileName=${fileName}`,
    { responseType: 'arraybuffer', headers: getToken() }
);